const production = process.env.NODE_ENV === 'production'
const version = process.env.APP_VERSION

// @ts-ignore
const APP_ENV = window.APP_ENV || {}


export const languages = [
  {
    code: "en",
    name: "English",
  },
  {
    code: "bg",
    country: "BG",
    name: "Български",
  },
  {
    code: "cs",
    country: "CZ",
    name: "Čeština",
  },
  {
    code: "de-DE",
    country: "DE",
    name: "Deutsch (DE)",
  },
  {
    code: "de-AT",
    country: "AT",
    name: "Deutsch (AT)",
  },
  {
    code: "et",
    country: "EE",
    name: "Eesti",
  },
  {
    code: "el",
    country: "GR",
    name: "Ελληνικά",
  },
  {
    code: "hr",
    country: "HR",
    name: "Hrvatski",
  },
  {
    code: "lv",
    country: "LV",
    name: "Latviešu",
  },
  {
    code: "lt",
    country: "LT",
    name: "Lietuvių",
  },
  {
    code: "hu",
    country: "HU",
    name: "Magyar",
  },
  {
    code: "ro",
    country: "RO",
    name: "Română",
  },
  {
    code: "pl",
    country: "PL",
    name: "Polski",
  },
  {
    code: 'sr',
    country: 'RS',
    name: 'Српски',
  },
  {
    code: "sk",
    country: "SK",
    name: "Slovenčina",
  },
  {
    code: "sl",
    country: "SI",
    name: "Slovenščina",
  },
  {
    code: "tr",
    country: "TR",
    name: "Türkçe",
  },
  {
    code: "uk",
    country: "UA",
    name: "Українська",
  },
];

export default {
  // apiBaseUrl: APP_ENV.API_BASE_URL || 'https://appsonhms.com/api/client/',
  apiBaseUrl: APP_ENV.API_BASE_URL || 'http://localhost:7100/api/client/',
  staticBaseUrl: APP_ENV.STATIC_BASE_URL || '',
  production: production,
  version: version + (production ? '' : '-dev')
}

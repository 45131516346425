import apiClient from "@/api/ApiClient";
import {
  SearchResult,
  AppData,
  HomeData
} from "@/api/models";

export default {
  async getConfig(): Promise<any> {
    return (await apiClient.get(`/config`)).data;
  },
  async getHomepageData(lang: string): Promise<HomeData> {
    return (await apiClient.get(`/home?lang=${lang}`)).data;
  },
  async search(query: string): Promise<SearchResult[]> {
    return (await apiClient.get(`/apps?q=${encodeURIComponent(query)}`)).data;
  },
  async getApp(appId: string, lang: string): Promise<AppData> {
    return (await apiClient.get(`/apps/${appId}?lang=${lang}`)).data;
  },
};

import {createApp} from 'vue'
import {createI18n} from 'vue-i18n'
import Cookies from 'js-cookie'
import App from './App.vue'
import router from './ui/router'
import store from './store'
import ElementPlus from 'element-plus'
import strings from './strings'
import api from "@/api";
import {languages} from "@/config";


api.getConfig().then((config) => {
  let lang = navigator.language || 'en';

  const langCookie = Cookies.get('lang');
  if (langCookie) {
    lang = langCookie
  } else {
    if (config.country) {
      languages.forEach((l) => {
        if (l.country === config.country) {
          lang = l.code;
        }
      })
    }
  }

  console.log(lang, config.country)
  if (lang === "pl") {
    document.location.href = "https://appsonhms.com/pl";
  } else {
    const i18n = createI18n({
      legacy: true,
      locale: lang,
      fallbackLocale: 'en',
      messages: strings,
    })

    createApp(App)
      .use(i18n)
      .use(store)
      .use(router)
      .use(ElementPlus)
      .mount('#app')
  }
});


import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '@/ui/sections/Home.vue'
import AppDetails from '@/ui/sections/AppDetails.vue'
import NoResults from '@/ui/sections/NoResults.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/app/:appId',
    name: 'AppDetails',
    component: AppDetails,
    props: true
  },
  {
    path: '/notfound',
    name: 'NoResults',
    component: NoResults,
    props: (route) => ({ query: route.query.q }),
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 }
  },
  routes
})

export default router

export default {
  en: {
    about_button: 'About',
    about_text: 'AppsOnHMS is a learning portal about app availability on different stores, and how to install the desired application on your Huawei device. The results shown are generated from public available content and may change from time to time',
    tagline: 'Your <strong>online library</strong> for apps on <strong>HUAWEI devices</strong>',
    search_placeholder: 'Find an app...',
    description_appgallery: 'Huawei AppGallery is the official Huawei app store that you can use to download hundreds of Android apps. The app works perfectly with any smartphone that has the Android operating system, but you do need to have the Huawei mobile services app installed in your smartphone for it to work correctly for other brands.',
    description_petalsearch: 'Petal Search is a Huawei developed search engine app that allows users to easily search for and find the things they need – including apps, news, videos and more.',
    description_gspace: 'New 2021 method to use hot apps, working all apps in your phone. Gspace provides the function that you can easily use one phone to log in multiple accounts and keep them all online at the same time! Data from different accounts will not interfere with each other.',
    description_apkpure: 'APKPure (APK Downloader) App is officially released! With APKPure, you can instantly download and update region locked (not available in your country), pre-registered games and many other Apps on Android.',
    featured_title: 'Featured <strong>Apps &amp; Games</strong>',
    sources_title: 'Available on',
    curated_guides_title: 'Guides',
    youtube_guides_title: 'Results from YouTube',
    youtube_search_title: 'Find More',
    youtube_search_button: 'Find more guides on YouTube',
    no_results_title: 'We couldn’t find a match for: ',
    no_results_text: 'Please try another search or watch the video suggestions from below on how to install apps',
    stores_title: 'A variety of methods to <strong>download</strong>,<br/> <strong>install</strong> and <strong>use apps</strong>',
    store_info_button: 'Read More',
    store_visit_button: 'Go To',

    app_details_button: 'More',
    faq_title: 'Frequently Asked Questions',
    faq_question_1: 'What is Gbox?',
    faq_answer_1: 'GBox is an app that provides a variety of features, including privacy protection and the ability to run multiple copies of an app. You can also use apps relying on GMS on devices that do not support GMS through GBox. with one-time installation, you can enjoy the features of GBox on any Android phone.',
    faq_question_2: 'GBox Safety and Security issue',
    faq_answer_2: 'GBox will never collect any personal data. Your privacy and data security are ensured.',
    faq_question_3: 'Why GBox apply for so many permissions?',
    faq_answer_3: 'GBox applies for necessary permissions to ensure the apps can function properly. For example, GBox applies for notification permissions to ensure users can receive the message notification in time.',
    support_links_title: 'For more <strong>product related questions</strong>',
    service_title: 'Service Center',
    service_action: 'Find a Service Center',
    help_center_title: 'Find more',
    help_center_action: 'More info on Devices',
    contact_title: 'Contact',
    contact_action: 'Hotline',

    survey_title: 'We value your feedback!',
    survey_description: 'Help us improve your experience by taking our survey',
    survey_button: 'Take the survey'
  },
  ro: {
    about_button: 'Despre',
    about_text: 'AppsOnHMS este un portal de învățare despre disponibilitatea aplicațiilor din diferite magazine și oferă de asemenea îndrumare pentru instalarea  aplicațiilor dorite pe dispozitivul dvs. Huawei. Rezultatele reprezintă conținutul public disponibil și poate suferi actualizări.',
    tagline: 'Biblioteca dvs. online pentru aplicații pe dispozitivele Huawei',
    search_placeholder: 'Caută o aplicație...',
    description_appgallery: 'Huawei AppGallery este magazinul oficial de aplicații Huawei pe care îl puteți utiliza pentru a descărca sute de aplicații disponibile pe Android. Aplicația funcționează perfect cu orice smartphone care are sistemul de operare Android, dar necesită instalarea aplicației de servicii mobile Huawei pe smartphone-ul dvs. pentru a funcționa corect și pentru alte mărci.',
    description_petalsearch: 'Petal Search reprezintă un motor de căutare dezvoltat de către Huawei care permite utilizatorilor să caute și să găsească cu ușurință lucrurile de care au nevoie - inclusiv aplicații, știri, videoclipuri și multe altele.',
    description_gspace: 'Nouă metodă dezvoltată în 2021 pentru a utiliza aplicațiile momentului. Gspace oferă funcția prin care puteți utiliza cu ușurință un singur telefon pentru a vă conecta la mai multe conturi și a le menține pe toate online, în același timp! Datele asociate conturilor diferite nu vor interfera între ele.',
    description_apkpure: 'Aplicația APKPure (APK Downloader) este lansată oficial! Cu APKPure, puteți descărca și actualiza instantaneu regiunea blocată (nu este disponibilă în țara dvs.), jocurile preînregistrate și multe alte aplicații disponibile pe Android.',
    featured_title: '<strong>Aplicații &amp; Jocuri</strong> Populare',
    sources_title: 'Disponibil pe',
    curated_guides_title: 'Ghid',
    youtube_guides_title: 'Rezultate de pe YouTube',
    youtube_search_title: 'Găsiți mai multe',
    youtube_search_button: 'Găsiți mai multe sugestii pe YouTube',
    no_results_title: 'Din păcate nu am găsit rezultate pentru:',
    no_results_text: 'Editați filtrele de căutare sau urmăriți sugestiile video de mai jos despre cum să instalați aplicații',
    stores_title: 'O varietate de metode de descărcare, instalare și utilizare a aplicațiilor',
    store_info_button: 'Mai multe',
    store_visit_button: 'Află mai multe',

    app_details_button: 'Mai multe',
    faq_title: 'Întrebări frecvente',
    faq_question_1: "Ce este Gbox?",
    faq_answer_1: "GBox este o aplicație care oferă o varietate de funcții, inclusiv protecție a confidențialității și capacitatea de a rula mai multe versiuni ale unei aplicații. De asemenea, puteți utiliza pe dispozitive, aplicații care se bazează pe GMS dar care nu acceptă GMS prin GBox cu o singură instalare, vă puteți bucura de funcțiile GBox pe orice telefon cu Android. ",
    faq_question_2: "Gbox Siguranță și securitate",
    faq_answer_2: "GBox nu va colecta niciodată date personale. Confidențialitatea și securitatea datelor dvs. sunt asigurate.",
    faq_question_3: "De ce GBox solicită atât de multe permisiuni?",
    faq_answer_3: "GBox solicită permisiunile necesare pentru a se asigura că aplicațiile pot funcționa corect. De exemplu, GBox solicită permisiuni pentru notificări pentru a se asigura că utilizatorii pot primi  mesajele la timp.",
    support_links_title: 'Mai multe întrebări legate de produse',
    service_title: 'Service Center',
    service_action: 'Găsește un Service Center',
    help_center_title: 'Află mai multe',
    help_center_action: 'Mai multe informații',
    contact_title: 'Contactează-ne',
    contact_action: 'Hotline',

    survey_title: 'Opinia ta conteaza!',
    survey_description: 'Dorim să vă îmbunătățim experiența de utilizare prin acest sondaj.',
    survey_button: 'Click aici'
  },
  pl: {
    about_button: 'O stronie',
    about_text: 'AppsOnHMS to portal, na którym sprawdzisz dostępność aplikacji oraz poznasz sposób ich instalacji na urządzeniach HUAWEI. Prezentowane wyniki są generowane na podstawie publicznie dostępnych treści i mogą od czasu do czasu ulegać zmianie.',
    tagline: 'Twoja <strong>biblioteka online</strong> dla aplikacji na <strong>urządzeniach HUAWEI</strong>',
    search_placeholder: 'Znajdź aplikację...',
    description_appgallery: 'HUAWEI AppGallery to oficjalny sklep z aplikacjam, z którego można pobrać tysiące aplikacji na Androida. Aby korzystać z AppGallery na smartfonach innych producentów, wystarczy pobrać ją z oficjalnej strony producenta. Niezbędne będzie również zainstalowanie pakietu usług HMS.',
    description_petalsearch: 'Petal to opracowana przez HUAWEI wyszukiwarka, która umożliwia szybkie i wygodne znalezienie niezbędnych aplikacji i treści, w tym wiadomości, filmów itp.',
    description_gspace: 'Nowa metoda korzystania z najpopularniejszych aplikacji. Gspace umożliwia zalogowanie się do kilku kont i korzystanie z nich jednocześnie. Dane z różnych kont nie będą ze sobą kolidować.',
    description_apkpure: 'APKPure (APK Downloader) oficjalnie dostępny! Dzięki niej możesz uruchamiać gry i aplikacje zablokowane w Twoim regionie.',
    featured_title: 'Polecane <strong>aplikacje &amp; gry</strong>',
    sources_title: 'Dostępna na',
    curated_guides_title: 'Porady',
    youtube_guides_title: 'Wyniki z YouTube',
    youtube_search_title: 'Znajdź więcej',
    youtube_search_button: 'Znajdź więcej porad na YouTube',
    no_results_title: 'Brak wyników dla:',
    no_results_text: 'Spróbuj wyszukać inną frazę lub sprawdź poniższe filmy ze wskazówkami dotyczącymi instalowania aplikacji',
    stores_title: 'Różnorodne metody <strong>pobierania</strong>, <strong>instalowania</strong> i <strong>korzystania z aplikacji</strong>',
    store_info_button: 'Dowiedz się więcej',
    store_visit_button: 'Przejdź do',
    app_details_button: 'Więcej',

    faq_title: 'Najpopularniejsze pytania',
    faq_question_1: "Czym jest Gspace i jak działa?",
    faq_answer_1: "Gspace to zewnętrzny sklep z aplikacjami pozwalający na używanie aplikacji oraz rozwiązań Google na twoim urządzeniu. Gspace to prosty sposób by używać map Google, Uber, YouTube oraz milion innych aplikacji, których możesz potrzebować.",
    faq_question_2: "Bezpieczeństwo danych, a Gspace",
    faq_answer_2: "Prywatne dane użytkownika nie będą gromadzone ani wykorzystywane przez Gspace. Konta użytkownika są weryfikowane przez serwery własne poszczególnych aplikacji. ",
    faq_question_3: "Czy aplikacje używane za pomocą Gspace będą miały pełną funkcjonalność?",
    faq_answer_3: "Prawie wszystkie aplikacje mogą być używane i pobrane z pełną funkcjonalnością. Dedykowany zespół w Gspace ma za zadanie ciągłe usprawnianie doświadczeń użytkowników. Jednak, w razie pojawienia się problemów, użytkownicy mogą zgłosić swoje uwagi w dowolnym momencie za pomocą paska nawigacji Gspace.",
    support_links_title: 'Więcej <strong>pytań dotyczących produktów</strong>',
    service_title: 'Serwis',
    service_action: 'Znajdź centrum serwisowe',
    help_center_title: 'Dowiedz się więcej',
    help_center_action: 'Więcej informacji o urządzeniach',
    contact_title: 'Kontakt',
    contact_action: 'Infolinia',
  },
  tr: {
    about_button: 'AppsONHMS Hakkında',
    about_text: 'AppsOnHMS, farklı mağazalarda uygulama kullanılabilirliği ve istenen uygulamanın Huawei cihazınıza nasıl kurulacağı hakkında bir öğrenme portalıdır. Gösterilen sonuçlar herkese açık içerikten oluşturulmuştur ve zaman zaman değişebilir.',
    tagline: 'Sizin <strong> online kitaplığınız </strong> uygulamalar icin <strong> HUAWEI cihazlari </strong>',
    search_placeholder: 'Uygulama bulun...',
    description_appgallery: 'Huawei AppGallery, yüzlerce Android uygulamasını indirmek için kullanabileceğiniz resmi Huawei uygulama mağazasıdır. Uygulama, Android işletim sistemine sahip tüm akıllı telefonlarınızla mükemmel şekilde çalışır, ancak diğer marka telefonlarda çalışabilmesi için akıllı telefonunuzda Huawei Mobil Hizmetler uygulamasının yüklü olması gerekir.',
    description_petalsearch: 'Petal Search, kullanıcıların uygulamalar, haberler, videolar ve daha fazlası dahil olmak üzere ihtiyaç duydukları şeyleri kolayca aramasını ve bulmasını sağlayan, Huawei tarafından geliştirilmiş bir arama motoru uygulamasıdır.',
    description_gspace: 'Telefonunuzdaki tüm uygulamaları çalıştıran yeni 2021 yöntemi. Gspace, birden fazla hesapta oturum açma ve hepsini aynı anda çevrimiçi kullanabileceğiniz işlevi sağlar! Farklı hesaplardan gelen veriler birbiriyle karışmaz.',
    description_apkpure: 'APKPure (APK Downloader) uygulaması resmen yayınlandı! APKPure ile, bölge erişimi engellenmiş (ülkenizde mevcut değildir), önceden kayıtlı oyunları ve Android\'deki diğer birçok uygulamayı anında indirebilir ve güncelleyebilirsiniz.',
    featured_title: 'Öne çıkan oyunlar',
    sources_title: 'da mevcut',
    curated_guides_title: 'Kılavuzlar',
    youtube_guides_title: 'YouTube sonuçları',
    youtube_search_title: 'Daha fazla bul',
    youtube_search_button: 'YouTube\'da daha fazla kılavuz bulun',
    no_results_title: 'Şunun için bir arama sonucu bulamadık:',
    no_results_text: 'Lütfen başka bir arama yapmayı deneyin veya uygulamaların nasıl yükleneceğiyle ilgili aşağıdaki video önerilerini izleyin.',
    stores_title: 'Ceşitli yöntemler <strong> indir </strong>, <strong>indir<strong> ve <strong> uygulamalari kullan</strong>',
    store_info_button: 'Daha fazla oku',
    store_visit_button: 'Git',
    app_details_button: 'Daha fazla',

    faq_title: 'Sıkça Sorulan Sorular',
    faq_question_1: "Gbox nedir?",
    faq_answer_1: "Gbox, GMS desteklemeyen cihazlarda, GMS bağımlılığı yüksek olan uygulamaları çalıştıran, aynı uygulamanın birden fazla kopyasını kullanmanıza izin veren ve yüksek seviyede veri gizliliği sunan bir uygulamadır. Tek seferlik bir yükleme sonrasında, herhangi bir Android cihazda Gbox'ın sunduğu özelliklere erişebilirsiniz.",
    faq_question_2: "Gbox",
    faq_answer_2: "Gbox hiç bir kişisel veri toplamaz ve bu sayede gizliliğiniz ve veri güvenliğiniz sağlanır.",
    faq_question_3: "Gbox neden çok sayıda izne ihtiyaç duyar?",
    faq_answer_3: "Gbox, uygulamanın stabil ve akıcı çalışması için gerekli izinlere başvurur. Örnek; Gbox uygulaması bildirim iznini sizden isteyerek, kullandığınız uygulamalardan gelecek bildirimleri tam zamanında almanızı sağlar.",
    support_links_title: 'Daha fazla <strong> ürünle ilgili sorular</strong>',
    service_title: 'Servis Merkezi',
    service_action: 'Servis Merkezi Bul',
    help_center_title: 'Daha fazla bul',
    help_center_action: 'Cihazlar hakkında daha fazla bilgi',
    contact_title: 'Iletisim',
    contact_action: 'Yardım hattı',

    survey_title: 'Sizin görüşlerinizi önemsiyoruz!',
    survey_description: 'Anketimize katılarak deneyiminizi iyileştirmemize destek olun.',
    survey_button: 'Anketimize katılın.'
  },
  de: {
    about_button: 'Über AppsOnHMS',
    about_text: 'AppsOnHMS ist ein Lernportal über die Verfügbarkeit von Apps in verschiedenen Stores und darüber, wie du die gewünschte Anwendung auf deinem Huawei-Gerät installieren kannst. Die angezeigten Ergebnisse werden aus öffentlich verfügbaren Inhalten generiert und können sich von Zeit zu Zeit ändern.',
    tagline: 'Deine <strong>Online-Bibliothek</strong> für Apps auf <strong>HUAWEI Geräten</strong>',
    search_placeholder: 'Finde eine App...',
    description_appgallery: 'Die Huawei AppGallery ist der offizielle Huawei App Store, über den du Hunderte von Android-Apps herunterladen kannst. Die App funktioniert perfekt mit jedem Smartphone mit Android-Betriebssystem, aber du musst die Huawei Mobile Services App auf deinem Smartphone installiert haben, damit sie für andere Marken korrekt funktioniert.',
    description_petalsearch: 'Petal Search ist eine von Huawei entwickelte Suchmaschinen-App, mit der Nutzer ganz einfach die Dinge suchen und finden können, die sie brauchen - einschließlich Apps, Nachrichten, Videos und mehr.',
    description_gspace: 'Die neue Methode von 2021 zur Nutzung von beliebten Apps funktioniert für alle Apps auf deinem Smartphone. Gspace bietet dir die Möglichkeit, dich mit einem Smartphone auf mehreren Konten anzumelden und sie alle gleichzeitig online zu halten! Daten von verschiedenen Konten blockieren sich dadurch nicht gegenseitig.',
    description_apkpure: 'Die APKPure (APK Downloader) App ist offiziell veröffentlicht! Mit APKPure kannst du jederzeit vorregistrierte Spiele und viele andere Apps auf Android downloaden, die in deiner Region nicht verfügbar sind.',
    // featured_title: '',
    sources_title: 'Verfügbar auf',
    curated_guides_title: 'Guides',
    youtube_guides_title: 'Ergebnisse von YouTube',
    youtube_search_title: 'Mehr dazu',
    youtube_search_button: 'Mehr dazu auf YouTube',
    no_results_title: 'Wir konnten kein Suchergebnis finden für:',
    no_results_text: 'Bitte probiere einen anderen Suchbegriff oder sieh dir die Videovorschläge unten an, um mehr über das Installieren von Apps zu erfahren:',
    stores_title: 'Eine Vielzahl an verschiedenen Wegen, <strong> um Apps herunterladen,</strong>, <strong>installieren</strong> und <strong>nutzen zu können</strong>',
    store_info_button: 'Mehr lesen',
    store_visit_button: 'Mehr dazu',
    app_details_button: 'Mehr',

    faq_title: 'Häufig gestellte Fragen',
    faq_question_1: "Was ist GBox?",
    faq_answer_1: "GBox ist eine App, die eine Vielzahl von Funktionen bietet, darunter den Schutz deiner persönlichen Daten und auch die Möglichkeit, mehrere Versionen einer App auszuführen. Mit GBox kannst Apps verwenden, die GMS benötigen, auch wenn dein Gerät GMS nicht unterstützt. Mit einer einmaligen Installation kannst du alle Funktionen von GBox auf jedem Android Smartphone nutzen.",
    faq_question_2: "GBox Sicherheit und Schutz",
    faq_answer_2: "GBox wird niemals personenbezogene Daten sammeln. Deine Privatsphäre und Datensicherheit sind gewährleistet.",
    faq_question_3: "Warum benötigt GBox so viele Berechtigungen?",
    faq_answer_3: "GBox benötigt die erforderlichen Berechtigungen, um sicherzustellen, dass die Apps ordnungsgemäß funktionieren. Beispielsweise beantragt Gbox die Benachrichtigungsberechtigung, um sicherzustellen, dass Benutzer Benachrichtigungen rechtzeitig erhalten können.",
    support_links_title: 'Zu den <strong>produktbezogenen Fragen</strong>',
    service_title: 'Service Center',
    service_action: 'Finde ein Service Center',
    help_center_title: 'Mehr dazu',
    help_center_action: 'Mehr Infos zu den Geräten',
    contact_title: 'Kontakt',
    contact_action: 'Hotline',
  },
  lt: {
    about_button: 'Apie',
    about_text: '„AppsOnHMS“ yra mokomoji svetainė apie programėlių prieinamumą įvairiose parduotuvėse ir apie tai, kaip įdiegti norimą programėlę „Huawei“ įrenginyje. Rodomi rezultatai generuojami iš viešai prieinamo turinio ir kartais gali keistis.',
    tagline: 'Jūsų internetinė <strong> HUAWEI įrenginių </strong> programėlių <strong> biblioteka </strong>',
    search_placeholder: 'Surasti programėlę',
    description_appgallery: '„Huawei AppGallery“ yra oficiali „Huawei“ programėlių parduotuvė, kurioje galite atsisiųsti šimtus „Android“ programėlių. Programa puikiai veikia su bet kokiu išmaniuoju telefonu, kuriame yra „Android“ operacinė sistema, tačiau norint, kad ji tinkamai veiktų kitų prekinių ženklų atveju, išmaniajame telefone turi būti įdiegta „Huawei“ mobiliųjų paslaugų programa.',
    description_petalsearch: '„Petal Search“ yra „Huawei“ sukurta paieškos variklio programa, leidžianti vartotojams lengvai ieškoti ir rasti reikalingų dalykų, įskaitant programas, naujienas, vaizdo įrašus ir dar daugiau.',
    description_gspace: 'Naujas būdas naudoti populiariausias programėles jūsų telefone. „Gspace“ suteikia funkciją, kuria galite lengvai naudotis vienu telefonu, norėdami prisijungti prie kelių paskyrų ir tuo pačiu metu išlaikyti visas prisijungusias! Duomenys iš skirtingų paskyrų netrukdys vienas kitam.',
    description_apkpure: 'APKPure (APK Downloader) programa oficialiai išleista! Naudodami „APKPure“ galite nedelsdami atsisiųsti ir atnaujinti iš anksto užregistruotus žaidimus ir daugelį kitų „Android“ skirtų programų, kurios jūsų regionui nėra prieinamos.',
    featured_title: 'Siūlomos <strong>programėlės &amp; žaidimai</strong>',
    sources_title: 'Prieinama',
    curated_guides_title: 'Gidai',
    youtube_guides_title: '„YouTube“ paieškos rezultatai',
    youtube_search_title: 'Daugiau ieškokite',
    youtube_search_button: 'Daugiau informacijos „YouTube“',
    no_results_title: 'Nepavyko rasti atitikmens:',
    no_results_text: 'Pabandykite atlikti kitą paiešką arba žiūrėkite vaizdo įrašų pasiūlymus, kaip įdiegti programas, žemiau.',
    stores_title: 'Įvairūs būdai <strong>atsisiųsti</strong>, <strong>įdiegti</strong> ir<strong>naudoti programėles</strong>',
    store_info_button: 'Daugiau ieškokite',
    store_visit_button: 'Eiti į',
    app_details_button: 'Daugiau',

    faq_title: 'Dažnai užduodami klausimai',
    faq_question_1: "Kas yra „Gbox“?",
    faq_answer_1: "„GBox“ yra programėlė, teikianti įvairias funkcijas, įskaitant privatumo apsaugą ir galimybę paleisti kelias programėlės kopijas. Taip pat „GBox“ leidžia naudotis GMS  programėlėmis įrenginiuose, kurie nepalaiko GMS. Įdiegę vieną kartą, galėsite mėgautis „GBox“ funkcijomis bet kuriame „Android“ telefone.",
    faq_question_2: "Gbox biztonság",
    faq_answer_2: "A Gbox nem gyűjt személyes adatokat. Az adataid biztonsága garantált.",
    faq_question_3: "Miért van a Gbox-nak szüksége ilyen sok engedélyre?",
    faq_answer_3: "A GBox ahhoz kéri a szükséges engedélyeket, hogy az alkalmazások megfelelően működhessenek. A GBox például értesítési engedélyeket kér, hogy a felhasználók időben megkaphassák az üzenetekről szóló értesítéseket.",
    support_links_title: 'Daugiau <strong>su produktais susijusių klausimų</strong>',
    service_title: 'Serviso centras',
    service_action: 'Rasti serviso centrą',
    help_center_title: 'Rasti daugiau',
    help_center_action: 'Daugiau apie įrenginius',
    contact_title: 'Kontaktai',
    contact_action: 'Karštoji linija',
  },
  lv: {
    about_button: 'Par AppsOnHMS',
    about_text: 'AppsOnHMS ir mācību portāls par lietotņu pieejamību dažādos veikalos un to, kā savā Huawei ierīcē instalēt vēlamo lietojumprogrammu. Parādītie rezultāti tiek ģenerēti no publiski pieejama satura, un tie laiku pa laikam var mainīties',
    tagline: 'Jūsu <strong>online library</strong> lietotnēm uz <strong>HUAWEI ierīces</strong>',
    search_placeholder: 'Atrast lietotni...',
    description_appgallery: 'Huawei AppGallery ir oficiālais Huawei lietotņu veikals, ko varat izmantot, lai lejupielādētu simtiem Android lietotņu. Lietotne lieliski darbojas ar jebkuru viedtālruni, kurā ir Android operētājsistēma, taču, lai tā darbotos pareizi citiem zīmoliem, viedtālrunī ir jābūt instalētai Huawei mobilo pakalpojumu lietotnei.',
    description_petalsearch: 'Petal Search ir Huawei izstrādāta meklētājprogrammas lietotne, kas lietotājiem ļauj viegli meklēt un atrast vajadzīgās lietas, tostarp lietotnes, ziņas, videoklipus un daudz ko citu.',
    description_gspace: 'Jauna 2021. gada metode pieprasītāko lietotņu izmantošanai, strādājot ar visām lietotnēm tālrunī. Gspace nodrošina funkciju, lai jūs varētu viegli izmantot vienu tālruni, lai pieteiktos vairākos kontos un vienlaikus saglabātu tos visus tiešsaistē! Dati no dažādiem kontiem netraucēs viens otram.',
    description_apkpure: 'APKPure (APK Downloader) lietotne ir oficiāli izlaista! Izmantojot APKPure, Android ierīcē varat uzreiz lejupielādēt un atjaunināt bloķētu reģionu (nav pieejams jūsu valstī), iepriekš reģistrētas spēles un daudzas citas lietotnes.',
    featured_title: 'Piedāvātās <strong>Lietotnes &amp; Spēles</strong>',
    sources_title: 'Pieejams',
    curated_guides_title: 'Pamācībās',
    youtube_guides_title: 'YouTube rezultātos',
    youtube_search_title: 'Uzzināt vairāk',
    youtube_search_button: 'Atrast vairāk pamācības YouTube',
    no_results_title: 'Mēs nevarējām atrast atbilstību:',
    no_results_text: 'Lūdzu, mēģiniet citu meklējumu vai noskatieties video ierosinājumus zemāk, kā iestatīt lietotnes',
    stores_title: 'Vairākas metodes <strong>lejuplādēt</strong>, <strong>instalēt</strong> un <strong>lietot lietotnes </strong>',
    store_info_button: 'Uzzināt vairāk',
    store_visit_button: 'Iet uz',
    app_details_button: 'Vairāk',

    faq_title: 'Biežāk uzdotie jautājumi',
    faq_question_1: "Kas yra „Gbox“?",
    faq_answer_1: "„GBox“ yra programėlė, teikianti įvairias funkcijas, įskaitant privatumo apsaugą ir galimybę paleisti kelias programėlės kopijas. Taip pat „GBox“ leidžia naudotis GMS  programėlėmis įrenginiuose, kurie nepalaiko GMS. Įdiegę vieną kartą, galėsite mėgautis „GBox“ funkcijomis bet kuriame „Android“ telefone.",
    faq_question_2: "„GBox“ saugumo problema",
    faq_answer_2: "„GBox“ niekada nerinks jokių asmeninių duomenų. Jūsų privatumas ir duomenų saugumas užtikrinamas.",
    faq_question_3: "Kodėl „GBox“ prašo tiek daug leidimų?",
    faq_answer_3: "„GBox“ prašo reikalingų leidimų, kad užtikrintų, jog programos veiktų tinkamai. Pavyzdžiui, „GBox“ prašo pranešimų leidimų, kad vartotojai galėtų laiku gauti žinučių pranešimus.",
    support_links_title: 'Vairāk <strong>jautājumi par produktiem</strong>',
    service_title: 'Servisa centrs',
    service_action: 'Atrast servisa centru',
    help_center_title: 'Atrast vairāk',
    help_center_action: 'Vairāk informācijas par ierīcēm',
    contact_title: 'Kontakti',
    contact_action: 'Info tālrunis',
  },
  et: {
    about_button: 'Teave AppsOnHMS\'i kohta',
    about_text: 'AppsOnHMS on õppeportaal äppide kättesaadavuse kohta erinevates kauplustes ja kuidas paigaldada soovitud rakendus oma Huawei seadmesse. Kuvatavad tulemused luuakse avalikust saadaolevast sisust ja võivad aeg-ajalt muutuda',
    tagline: 'Sinu <strong>veebiraamatukogu</strong> äppide jaoks <strong>HUAWEI devices</strong>',
    search_placeholder: 'Leia äpp',
    // description_appgallery: '',
    description_petalsearch: 'Petal Search on Huawei väljatöötatud otsingumootori äpp, mis võimaldab kasutajatel hõlpsalt otsida ja leida asju, mida nad vajavad - sealhulgas äppe, uudiseid, videoid ja palju muud.',
    description_gspace: 'Uus 2021. aasta meetod kuumade äppide kasutamiseks, töötades kõigi äppidega sinu telefonis. Gspace pakub funktsiooni, et saate hõlpsasti kasutada ühte telefoni, et logida sisse mitmele kontole ja hoida neid kõiki korraga võrgus! Erinevate kontode andmed ei häiri üksteist.',
    description_apkpure: 'APKPure (APK Downloader) äpp on ametlikult välja antud! APKPure abil saate koheselt alla laadida ja värskendada lukustatud piirkonda (pole teie riigis saadaval), eelregistreeritud mänge ja paljusid teisi Androidi äppe.',
    featured_title: 'Esiletõstetud <strong>Äpid > Mängud</strong>',
    sources_title: 'Saadaval',
    curated_guides_title: 'Juhendid',
    youtube_guides_title: 'Tulemused YouTube\'ist',
    youtube_search_title: 'Otsi rohkem',
    youtube_search_button: 'Otsi rohkem juhendeid YouTube\'ist',
    no_results_title: 'Me ei leidnud vastet:',
    no_results_text: 'Proovige mõnda muud otsingut või vaadake allpool toodud videosoovitusi äppide paigaldamise kohta',
    stores_title: 'Erinevad meetodid, et <strong>alla laadida</strong>, <strong>paigaldada</strong> ja <strong>kasutada äppe</strong>',
    store_info_button: 'Loe lähemalt',
    store_visit_button: 'Mine',
    app_details_button: 'Rohkem',

    faq_title: 'Korduma kippuvad küsimused',
    faq_question_1: "Mis on GBox?",
    faq_answer_1: "GBox on rakendus, mis pakub erinevaid funktsioone, sealhulgas privaatsuskaitset ja võimalust kasutada rakendusest mitut koopiat.  Samuti võimaldab GBox kasutada GMS-i rakendusi seadmetes, mis ei toeta GMS-i. Pärast installimist saate nautida GBoxi funktsioone mis tahes Android-telefonis.",
    faq_question_2: "GBoxi ohutus ja turvalisus",
    faq_answer_2: "GBox ei kogu kunagi isikuandmeid. Teie privaatsus ja andmete turvalisus on tagatud.",
    faq_question_3: "Miks GBox nii palju lubasid küsib?",
    faq_answer_3: "GBox taotleb vajalikke õigusi, et tagada rakenduste nõuetekohane töö. Näiteks taotleb GBox teavitusõigusi, et kasutajad saaksid õigeaegselt teated kätte.",
    support_links_title: 'Kui soovite rohkem <strong>tootega seotud küsimusi</strong>',
    service_title: 'Teeninduskeskus',
    service_action: 'Leia teeninduskeskus',
    help_center_title: 'Leia rohkem',
    help_center_action: 'Lisateave seadmete kohta',
    contact_title: 'Kontakt',
    contact_action: 'Kuum liin',
  },
  bg: {
    about_button: 'Всичко за AppsOnHMS',
    about_text: 'AppsOnHMS е учебен портал за наличността на мобилни приложения в различни магазини и как да инсталирате желаното от вас приложение на вашето устройство Huawei. Показаните резултати са генерирани от публично достъпно съдържание и могат да се променят от време на време',
    tagline: 'Вашия <strong>онлайн портал</strong> за приложения на <strong>устройства HUAWEI</strong>',
    search_placeholder: 'Намерете приложение...',
    description_appgallery: 'Huawei AppGallery е официалният магазин за приложения на Huawei, който можете да използвате, за да изтеглите стотици приложения за Android. Приложението работи перфектно с всеки смартфон с операционна система Android, но трябва да инсталирате първо приложението HMS Core на вашия смартфон(изтеглено от AppGallery), за да работи правилно за други марки.',
    description_petalsearch: 'Petal Search е приложение тип  търсачка, разработено от Huawei, което позволява на потребителите лесно да търсят и намират нещата, от които се нуждаят - включително приложения, новини, видеоклипове и др.',
    description_gspace: 'Нов метод 2021 за използване на топ приложения, работещ с всички приложения във вашия телефон. Gspace предоставя функциалността, че можете лесно да използвате един телефон, за да влезете в няколко акаунта и да ги държите всички онлайн едновременно! Данните от различни акаунти няма да си пречат.',
    description_apkpure: 'Приложението APKPure (APK Downloader) вече е официално налично! С APKPure можете незабавно да изтеглите и актуализирате вашия регион, (заключени за вашата страна), предварително регистрирани игри и много други приложения на Android.',
    featured_title: 'Представени <strong>Приложения &amp; Игри</strong>',
    sources_title: 'Предлага се на',
    curated_guides_title: 'Ръководства',
    youtube_guides_title: 'Резултати от YouTube',
    youtube_search_title: 'Намери още',
    youtube_search_button: 'Намерете още ръководства в YouTube',
    no_results_title: 'Не можахме да намерим съвпадение за',
    no_results_text: 'Моля, опитайте с друго търсене или гледайте видео предложения по-долу за това как да инсталирате приложения',
    stores_title: 'Разнообразие от методи за <strong>изтегляне</strong>, <strong>инсталиране</strong> и <strong>използване на приложения</strong>',
    store_info_button: 'Прочети още',
    store_visit_button: 'Отиди на',
    app_details_button: 'Повече',

    faq_title: 'Често задавани въпроси',
    faq_question_1: "Какво е Gbox?",
    faq_answer_1: "Gbox е приложение, което предлага многожество функции, включително защита на неприкосновеността на данните и възможност за стартиране на множество копия на едно приложение. Чрез него можете и да използвате приложения, изискващи GMS, на устройства, които не поддържат GMS. С еднократно му инсталиране можете да радвате на възможностите на Gbox на всеки Android смартфон.",
    faq_question_2: "Сигурност и защита по отношение на Gbox",
    faq_answer_2: "Gbox никога няма да събира каквито и да било лични данни. Вашата сигурност и неприкосновеност са гарантирани.",
    faq_question_3: "Защо Gbox изисква толкова много разрешения?",
    faq_answer_3: "Gbox изисква необходимите разрешения, за да гарантира пълноценната работа на приложенията. Например, Gbox иска разрешения за нотификации, за да гарантира, че потребителите ще бъдат своевременно уведомявани за получаването на съобщения.",
    support_links_title: 'За още <strong>въпроси, свързани с продукта</strong>',
    service_title: 'Сервизен център',
    service_action: 'Намерете сервизен център',
    help_center_title: 'Открий още',
    help_center_action: 'Повече информация за Устройствата',
    contact_title: 'Контакти',
    contact_action: 'Гореща линия',
  },
  cs: {
    about_button: 'O aplikacích HMS',
    about_text: 'AppsOnHMS je vzdělávací portál informující o dostupnosti aplikací v různých obchodech a také o tom, jak nainstalovat požadovanou aplikaci do tvého zařízení HUAWEI. Zobrazené výsledky jsou generovány z veřejně dostupného obsahu a mohou se čas od času změnit.',
    tagline: 'Vaše <strong>online knihovna</strong> aplikací na <strong>HUAWEI zařízeních</strong>',
    search_placeholder: 'Najít aplikaci...',
    description_appgallery: 'Huawei AppGallery je oficiální obchod s aplikacemi HUAWEI, pomocí kterého si můžeš stáhnout stovky aplikací pro Android. Tato aplikace funguje perfektně s jakýmkoli chytrým telefonem, který má operační systém Android, ale zároveň musíš mít ve svém chytrém telefonu nainstalovanou aplikaci mobilních služeb HUAWEI, aby správně fungovala i pro jiné značky.',
    description_petalsearch: 'Petal Search je aplikace pro vyhledávače vyvinutá společností HUAWEI, která uživatelům umožňuje snadno vyhledávat a nalézat věci, které potřebují - včetně aplikací, zpráv, videí a dalšího.',
    description_gspace: 'Jde o novou 2021 metodu pro používání oblíbených aplikací, která funguje se všemi aplikacemi v telefonu. Gspace poskytuje funkci, pomocí které můžeš s jedním telefonem snadno přihlásit více účtů a současně je nechat všechny online! Data z různých účtů si nebudou navzájem překážet.',
    description_apkpure: 'Aplikace APKPure (APK Downloader) je oficiálně vydána! S APKPure můžeš okamžitě stahovat a aktualizovat uzamčené oblasti (ty, které nejsou k dispozici ve tvé zemi), předregistrované hry a mnoho dalších aplikací pro Android.',
    featured_title: 'Doporučené hry',
    sources_title: 'Dostupné na',
    curated_guides_title: 'Průvodci',
    youtube_guides_title: 'Výsledky z Youtube',
    youtube_search_title: 'Najít více',
    youtube_search_button: 'Najdi více průvodců na Youtube',
    no_results_title: 'Nenašli jsme shodu pro:',
    no_results_text: 'Zkuste prosím jiný vyhledávač nebo se podívejte na níže uvedená doporučení ohledně instalace aplikací',
    stores_title: 'Rozličné metody pro <strong>download</strong>, <strong>install</strong> a <strong>use apps</strong>',
    store_info_button: 'Číst víc',
    store_visit_button: 'Jít na',
    app_details_button: 'Více',

    faq_title: 'Často kladené otázky',
    faq_question_1: "Co je Gbox?",
    faq_answer_1: "GBox je aplikace, která poskytuje řadu funkcí, včetně ochrany soukromí a možnosti spouštět více kopií aplikace. Prostřednictvím Gbox můžete používat GMS aplikace na zařízeních, která nepodporují GMS. S jednorázovou instalací si můžete užívat funkce GBox na jakémkoli telefonu Android.",
    faq_question_2: "Problém bezpečnosti a zabezpečení Gbox",
    faq_answer_2: "GBox nikdy nebude shromažďovat žádné osobní údaje. Vaše soukromí a bezpečnost dat je zajištěna.",
    faq_question_3: "Proč GBox žádá o tolik oprávnění?",
    faq_answer_3: "GBox žádá o potřebná oprávnění, aby bylo zajištěno, že aplikace mohou správně fungovat. Například GBox žádá o oprávnění k oznámení, aby uživatelé mohli obdržet oznámení o zprávě.",
    support_links_title: 'Pro více <strong>produktových odpovědí</strong>',
    service_title: 'Servisní středisko',
    service_action: 'Najdi servisní středisko',
    help_center_title: 'Najdi více',
    help_center_action: 'Pro více informací o produktech',
    contact_title: 'Kontakt',
    contact_action: 'Zákaznický servis',
  },
  sk: {
    about_button: 'O aplikáciách HMS',
    about_text: 'AppsOnHMS je vzdelávací portál informujúci o dostupnosti aplikácií v rôznych obchodoch a taktiež o tom, ako nainštalovať požadovanú aplikáciu do tvojho zariadenia HUAWEI. Zobrazené výsledky sú generované z verejne dostupného obsahu a môžu sa z času na čas meniť.',
    tagline: 'Vaša <strong>online knižnica</strong> aplikácií pre <strong>zariadenia HUAWEI</strong>',
    search_placeholder: 'Nájsť aplikáciu...',
    description_appgallery: 'Huawei AppGallery je oficiálny obchod s aplikáciami HUAWEI, pomocou ktorého si môžeš stiahnuť  stovky aplikácií pre Android. Táto aplikácia funguje perfektne s akýmkoľvek smartfónom, ktorý má operační systém Android, ale zároveň musíš mať vo svojom smartfóne nainštalovanú aplikáciu mobilných služieb HUAWEI, aby správne fungovala aj pre iné značky.',
    description_petalsearch: 'Petal Search je aplikácia pre vyhľadávače vyvinutá spoločnosťou HUAWEI, ktorá užívateľom umožňuje jednoducho vyhľadávať a nájsť veci, ktoré potrebujú – vrátane aplikácií, správ, videí, atď. ',
    description_gspace: 'Nový spôsob používania obľúbených aplikácií, práca so všetkými aplikáciami v tvojom telefóne. Gspace umožňuje funkciu pomocou ktorej sa môžeš s jedným telefónom prihlásiť do viac účtov a nechať ich všetky online súčasne! Dáta z rôznych účtov budú spolu bez problémov fungovať.',
    description_apkpure: 'Aplikácia APKPure (APK Downloader) je oficiálne publikovaná! S APKPure môžeš okamžite sťahovať a aktualizovať uzamknuté oblasti (tie, ktoré nie sú k dispozícií v tvojej krajine), predregistrované hry a vela ďalších aplikácií pre Android.',
    featured_title: 'Odporúčané hry',
    sources_title: 'Dostupné na',
    curated_guides_title: 'Sprievodca',
    youtube_guides_title: 'Výsledky z YouTube',
    youtube_search_title: 'Nájsť viac',
    youtube_search_button: 'Násť viac sprievodcov na YouTube',
    no_results_title: 'Nenašla sa zhoda pre:',
    no_results_text: 'Skúste prosím iný vyhľadávač alebo sa pozrite na nižšie uvedené odporúčania ohľadne inštalácie aplikácií',
    stores_title: 'Rôžne spôsoby pre <strong>sťahovanie</strong>, <strong>inštaláciu</strong> a <strong>používanie aplikácií</strong>',
    store_info_button: 'Čitať viac',
    store_visit_button: 'Choď na',
    app_details_button: 'Viac',

    faq_title: 'Často kladené otázky',
    faq_question_1: "Čo je GBox?",
    faq_answer_1: "GBox je aplikácia, ktorá poskytuje množstvo funkcií vrátane ochrany súkromia a možnosti spúšťaž viacero kópii aplikácie. Prostredníctvom GBox môžete používať GMS aplikácie na zariadeniach, ktoré nepodporujú GMS. S jednorazovou inštaláciou si môžete užívať funkcie GBoxu na akomkoľvek telefóne so systémom Android.",
    faq_question_2: "Problém bezpečnosti a zabezpečenia GBox",
    faq_answer_2: "Gbox nezhromažďuje a  nikdy nebude zhromažďovať žiadne osobné údaje. Vaše súkromie a bezpečnosť údajov sú zaistené.",
    faq_question_3: "Prečo Gbox žiada o toľko povelení?",
    faq_answer_3: "GBox žiada o potrebné povolenia, aby sa zabezpečilo správne fungovanie aplikácií. Napríklad GBox žiada o povolenia upozornení, aby  používatelia mohli obdržať upozornenie na prijatú správu.",
    support_links_title: 'Pre viac <strong>otázok týkajúcich sa produktov</strong>',
    service_title: 'Servisné stredisko',
    service_action: 'Nájsť sevrisné stredisko',
    help_center_title: 'Nájsť viac',
    help_center_action: 'Pre viac informácií o produktoch',
    contact_title: 'Kontakt',
    contact_action: 'Zákaznícky servis',
  },
  el: {
    about_button: 'Σχετικά με',
    about_text: 'Το AppsOnHMS είναι ένα ενημερωτικό portal με πληροφορίες για την διαθεσιμότητα διαφόρων εφαρμογών σε διαφορετικά app stores και οδηγίες για το πως μπορείς να τις εγκαταστήσεις στην Huawei συσκευή σου. Οι πληροφορίες βασίζονται σε περιεχόμενο που είναι δημόσια διαθέσιμο και μπορεί να αλλάξει στο μέλλον.',
    tagline: 'Η διαδικτυακή βιβλιοθήκη σας για εφαρμογές σε συσκευές HUAWEI',
    search_placeholder: 'Βρες μία εφαρμογή...',
    description_appgallery: 'To Huawei AppGallery είναι το επίσημο app store της Huawei, όπου μπορείς να βρεις εκατοντάδες εφαρμογές. Η εφαρμογή δουλεύει άψογα σε όλα τα Android smartphones, αλλά σε κινητά άλλης εταιρίας, απαιτείται να έχετε εγκαταστήσει το Huawei mobile services app.',
    description_petalsearch: 'Το Petal Search είναι ένα app - μηχανή αναζήτησης που δίνει την δυνατότητα να αναζητήσεις και να βρεις ότι χρειάζεσαι (εφαρμογές, ειδήσεις, videos και πολλά περισσότερα)',
    description_gspace: 'Η νέα μέθοδος (2021) για να χρησιμοποιήσεις δημοφιλείς εφαρμογές - δουλεύει με όλες τις εφαρμογές στο τηλέφωνό σου. To Gspace παρέχει τη δυνατότητα σύνδεσης σε πολλαπλούς λογαριασμούς από ένα τηλέφωνο και μπορείς να παραμένεις online σε όλους αυτούς τους λογαριασμούς ταυτόχρονα. Τα δεδομένα από τους διαφορετικούς λογαριασμούς δεν θα επηρεάσουν την ταυτόχρονη λειτουργία. ',
    description_apkpure: 'Το APKPure (APK Downloader) έχει κυκλοφορήσει επίσημα. Με το APKPure μπορείς άμεσα να κατεβάσεις και να ενημερώσεις παιχνίδια και πολλές Android εφαρμογές που δεν είναι διαθέσιμα στην χώρα σου.',
    featured_title: 'Διαθέσιμες εφαρμογές και παιχνίδιδα',
    sources_title: 'Διαθέσιμα σε',
    curated_guides_title: 'Οδηγίες',
    youtube_guides_title: 'Αποτελέσματα από το YouTube',
    youtube_search_title: 'Ανακάλυψε περισσότερα',
    youtube_search_button: 'Βρες περισσότερες οδηγίες στο YouTube',
    no_results_title: 'Δεν μπορέσαμε να βρούμε αποτελέσματα για το:',
    no_results_text: 'Παρακαλούμε δοκίμασε άλλη αναζήτηση η δες τις προτάσεις στα παρακάτω video σχετικά με το πως να εγκαταστήσεις εφαρμογές',
    stores_title: 'Μια ποικιλία μεθόδων για λήψη, εγκατάσταση και χρήση εφαρμογών',
    store_info_button: 'Διάβασε περισσότερα',
    store_visit_button: 'Πήγαινε στο',
    app_details_button: 'Περισσότερα',

    faq_title: 'Συχνές απαντήσεις και ερωτήσεις',
    faq_question_1: "Τι είναι το Gbox;",
    faq_answer_1: "Το GBox είναι μια εφαρμογή που παρέχει μια ποικιλία λειτουργιών, όπως προστασία απορρήτου και δυνατότητα εκτέλεσης πολλαπλών αντιγράφων μιας εφαρμογής. Μπορείτε επίσης να χρησιμοποιήσετε εφαρμογές που βασίζονται στο GMS σε συσκευές που δεν υποστηρίζουν GMS μέσω του GBox. με εφάπαξ εγκατάσταση, μπορείτε να απολαύσετε τις δυνατότητες του GBox σε οποιοδήποτε τηλέφωνο Android.",
    faq_question_2: "GBox sigurnost i sigurnosne postakve",
    faq_answer_2: "GBox nikada neće prikupljati nikakve osobne podatke. Vaša privatnost i sigurnost podataka su zajamčeni.",
    faq_question_3: "Zašto GBox traži toliko dozvola?",
    faq_answer_3: "GBox traži potrebna dopuštenja kako bi se osiguralo ispravno funkcioniranje aplikacija. Na primjer, GBox traži dopuštenje za dozvole za obavijesti kako bi osigurao da korisnici mogu primiti obavijest o poruci na vrijeme.",
    support_links_title: 'Για περισσότερες ερωτήσεις που αφορούν τη συσκευή σας',
    service_title: 'Κέντρο εξυπηρέτησης',
    service_action: 'Βρείτε ένα κέντρο εξυπήρετησης',
    help_center_title: 'Βρείτε περισσότερα',
    help_center_action: 'Περισσότερες πληροφορίες για τις συσκευές',
    contact_title: 'Επικοινωνήστε μαζί μας',
    contact_action: 'Hotline',
  },
  hu: {
    about_button: 'Az AppsOnHMS-ről',
    about_text: 'Az AppsOnHMS egy tanulási portál az alkalmazások elérhetőségéről a különböző áruházakban, és arról, hogyan telepítheted a kívánt alkalmazást a HUAWEI készülékedre. A megjelenített eredmények nyilvánosan elérhető tartalmakból származnak, és időről időre változhatnak.',
    tagline: 'Online könyvtár a HUAWEI készülékek alkalmazásaihoz',
    search_placeholder: 'Keress egy alkalmazást...',
    description_appgallery: 'A HUAWEI AppGallery, a HUAWEI hivatalos alkalmazásboltja, amelyből több száz Android-alkalmazást tölthetsz le. Az alkalmazás tökéletesen működik minden olyan okostelefonnal, amely Android operációs rendszerrel rendelkezik, de ahhoz, hogy más márkák esetében is megfelelően működjön, a HUAWEI Mobile Service-nek telepítve kell lennie az okostelefonon.',
    description_petalsearch: 'A Petal Search egy HUAWEI által kifejlesztett keresőmotor-alkalmazás, amely lehetővé teszi a felhasználók számára, hogy könnyedén keressenek és megtalálják a számukra szükséges dolgokat - beleértve az alkalmazásokat, híreket, videókat és egyéb dolgokat.',
    description_gspace: 'Új 2021 módszer, a népszerű alkalmazások használatán alapul. A Gspace biztosítja azt a funkciót, hogy egy telefonnal könnyedén bejelentkezhetsz több fiókba, és mindet egyszerre online tarthatod! A különböző fiókok adatai nem zavarják egymást.',
    description_apkpure: 'APKPure (APK letöltő) alkalmazás hivatalosan megjelent! Az APKPure segítségével azonnal letöltheted és frissítheted a régiózáras (ami az országodban nem elérhető), előzetesen regisztrált játékokat és sok más alkalmazást androidos készülékeken.',
    featured_title: 'Kiemelt játékok',
    sources_title: 'Elérhető a',
    curated_guides_title: 'Útmutatók',
    youtube_guides_title: 'Eredmények a YouTube-ról',
    youtube_search_title: 'Tudj meg többet',
    youtube_search_button: 'További útmutatók a YouTube-on',
    no_results_title: 'Nem találtuk párját:',
    no_results_text: 'Kérjük próbálkozz egy másik kereséssel vagy tekints meg egy videót, hogy hogyan telepíts alkalmazásokat',
    stores_title: 'Számos megoldás, hogy hogyan tölts le, telepíts és használj alkalmazásokat',
    store_info_button: 'Olvass tovább',
    store_visit_button: 'Tovább',
    app_details_button: 'Tovább',

    faq_title: 'Gyakran Ismételt Kérdések',
    faq_question_1: "Mi a Gbox?",
    faq_answer_1: "A GBox egy olyan alkalmazás, amely számos funkciót kínál, többek között adatvédelmet és egy alkalmazás több példányának futtatását egyidejűleg. A GBoxon keresztül a GMS-re támaszkodó alkalmazásokat olyan eszközökön is használhatod, amelyek nem támogatják a GMS-t. Egyszeri telepítéssel bármelyik Android-telefonon élvezheted a GBox előnyeit.",
    faq_question_2: "Gbox biztonság",
    faq_answer_2: "A Gbox nem gyűjt személyes adatokat. Az adataid biztonsága garantált.",
    faq_question_3: "Miért van a Gbox-nak szüksége ilyen sok engedélyre?",
    faq_answer_3: "A GBox ahhoz kéri a szükséges engedélyeket, hogy az alkalmazások megfelelően működhessenek. A GBox például értesítési engedélyeket kér, hogy a felhasználók időben megkaphassák az üzenetekről szóló értesítéseket.",
    support_links_title: 'További termékkel kapcsolatos kérdésekért',
    service_title: 'Szervizközpont',
    service_action: 'Keress szervizközpontot',
    help_center_title: 'Tudj meg többet',
    help_center_action: 'További információ az eszközökről',
    contact_title: 'Elérhetőség',
    contact_action: 'Ügyfélszolgálati vonal',

    survey_title: 'Nagyra értékeljük a visszajelzésedet!',
    survey_description: 'Segíts nekünk az élmény javításában a kérdőív kitöltésével',
    survey_button: 'Töltsd ki a kérdőívet'
  },
  hr: {
    about_button: 'O aplikacijama',
    about_text: 'AppsOnHMS je portal za učenje o dostupnosti aplikacija u različitim trgovinama i kako instalirati željenu aplikaciju na vaš Huawei uređaj. Prikazani rezultati generirani su iz javno dostupnog sadržaja i mogu se s vremena na vrijeme promijeniti',
    tagline: 'Vaša <strong>online knjižnica </strong> za aplikacije na <strong>HUAWEI uređajima </strong>',
    search_placeholder: 'Pronađite aplikaciju',
    description_appgallery: 'Huawei AppGallery službena je trgovina aplikacija za Huawei koju možete koristiti za preuzimanje stotina Android aplikacija. Aplikacija savršeno funkcionira sa svakim pametnim telefonom koji ima operacijski sustav Android, ali morate imati instaliranu aplikaciju mobilnih usluga Huawei na svom pametnom telefonu kako bi ispravno radila za druge brendove.',
    description_petalsearch: 'Petal Search je aplikacija za pretraživanje aplikacija koju je razvio Huawei i omogućuje korisnicima jednostavno pretraživanje i pronalaženje stvari koje su im potrebne - uključujući aplikacije, vijesti, video zapise i još mnogo toga.',
    description_gspace: 'Nova metoda 2021. za korištenje popularnih aplikacija koja radi sa svim aplikacijama na vašem telefonu. Gspace nudi funkciju pomoću koje možete jednostavno koristiti jedan telefon za prijavu na više računa i držati ih sve na mreži u isto vrijeme! Podaci s različitih računa neće se međusobno miješati.',
    description_apkpure: 'Aplikacija APKPure (APK Downloader) službeno je objavljena! Uz APKPure možete trenutno preuzeti i ažurirati zaključanu regiju (nije dostupna u vašoj zemlji), unaprijed registrirane igre i mnoge druge aplikacije na Androidu.',
    featured_title: 'Istaknute <strong> aplikacije &amp; Igre </strong>',
    sources_title: 'Dostupno na',
    curated_guides_title: 'Vodič',
    youtube_guides_title: 'Rezultati s Youtube-a',
    youtube_search_title: 'Saznajte više',
    youtube_search_button: 'Više vodiča sa informacijama pronađite na Youtube-u',
    no_results_title: 'Nismo mogli pronaći par za:',
    no_results_text: 'Pokušajte s drugim pretraživanjem ili pogledajte video prijedloge u nastavku o tome kako instalirati aplikacije',
    stores_title: 'Različite metode za <strong> preuzimanje </strong>, <strong>instalaciju </strong> i <strong> korištenje aplikacija </strong>',
    store_info_button: 'Pročitajte više',
    store_visit_button: 'Idite na',
    app_details_button: 'Više',

    faq_title: 'Često postavljena pitanja',
    faq_question_1: "Što je GBox?",
    faq_answer_1: "GBox je aplikacija koja pruža niz značajki, uključujući zaštitu privatnosti i mogućnost pokretanja više kopija aplikacije. Također, putem GBoxa možete koristiti aplikacije koje se oslanjaju na GMS na uređajima koji ne podržavaju GMS. Uz jednokratnu instalaciju, možete uživati ​​u značajkama GBoxa na bilo kojem Android telefonu.",
    faq_question_2: "GBox sigurnost i sigurnosne postakve",
    faq_answer_2: "GBox nikada neće prikupljati nikakve osobne podatke. Vaša privatnost i sigurnost podataka su zajamčeni.",
    faq_question_3: "Zašto GBox traži toliko dozvola?",
    faq_answer_3: "GBox traži potrebna dopuštenja kako bi se osiguralo ispravno funkcioniranje aplikacija. Na primjer, GBox traži dopuštenje za dozvole za obavijesti kako bi osigurao da korisnici mogu primiti obavijest o poruci na vrijeme.",
    support_links_title: 'Za više <strong> pitanja o proizvodu </strong>',
    service_title: 'Servisni centar',
    service_action: 'Pronađite servisni centar',
    help_center_title: 'Pronađi više',
    help_center_action: 'Više informacija na uređajima',
    contact_title: 'Kontakt',
    contact_action: 'Kontaktni centar',
  },
  sr: {
    about_button: 'Saznaj više',
    about_text: 'AppsOnHMS je portal za učenje o dostupnosti aplikacija na različitim platformama i kako instalirati željenu aplikaciju na vaš Huawei uređaj. Prikazani rezultati generisani su iz javno dostupnog sadržaja i mogu se s vremena na vreme promeniti',
    tagline: 'Vaša <strong>online biblioteka</strong> za aplikacije na<strong>HUAWEI uređajima</strong>',
    search_placeholder: 'Pronađite aplikaciju',
    description_appgallery: 'Huawei AppGallery je zvanična Huawei prodavnica aplikacija, koju možete koristiti za preuzimanje stotina Android aplikacija. Aplikacija savršeno funkcioniše sa svim pametnim telefonima koji poseduju Android operativni sistem ali morate imati instaliranu aplikaciju Huawei mobilnih usluga kako bi radila na uređajima drugog brenda.',
    description_petalsearch: 'Petal Search je aplikacija za pretraživanje aplikacija koju je razvio Huawei i omogućava korisnicima jednostavno pretraživanje i pronalaženje stvari koje su im potrebne - uključujući aplikacije, vesti, video zapise i još mnogo toga.',
    description_gspace: 'Nova metoda 2021. za korišćenje popularnih aplikacija koja radi sa svim aplikacijama na vašem telefonu. Gspace nudi funkciju pomoću koje možete jednostavno koristiti jedan telefon za prijavu na više naloga i držati ih sve na mreži u isto vreme! Podaci sa različitih naloga neće se međusobno mešati.',
    description_apkpure: 'Aplikacija APKPure (APK Downloader) službeno je objavljena! Uz APKPure možete trenutno preuzeti i ažurirati zaključanu regiju (nije dostupna u vašoj zemlji), unapred registrovane igre i mnoge druge aplikacije na Androidu.',
    featured_title: 'Istaknute <strong> aplikacije & amp; Igre </strong>',
    sources_title: 'Dostupno na',
    curated_guides_title: 'Vodič',
    youtube_guides_title: 'Rezultati s Youtube-a',
    youtube_search_title: 'Saznajte više',
    youtube_search_button: 'Pronađite više informacija na na Youtube-u',
    no_results_title: 'Ne možemo naći poklapanje zaČ',
    no_results_text: '"Pokušajte s drugim pretraživanjem ili pogledajte video predloge u nastavku o tome kako instalirati aplikacije',
    stores_title: 'Raznovrsne metode za <strong>preuzimanje</strong>, <strong>instaliranje</strong> i <strong>korišćenje aplikacija</strong>',
    store_info_button: 'Pročitajte više',
    store_visit_button: 'Idite na',
    app_details_button: 'Više',

    faq_title: 'Često postavljena pitanja',
    faq_question_1: "Šta je Gbox?",
    faq_answer_1: "GBox je aplikacija koja pruža mnoštvo funkcija, uključujući zaštitu privatnosti i mogućnost da pokrenete više kopija aplikacije. Aplikacije koje se oslanjaju i na GMS možete koristiti i na uređajima koji ne podržavaju GMS putem GBox-a. uz jednovremenu instalaciju, možete uživati u funkcijama GBox-a na bilo kom Android telefonu.",
    faq_question_2: "Problém bezpečnosti a zabezpečenia GBox",
    faq_answer_2: "Gbox nezhromažďuje a  nikdy nebude zhromažďovať žiadne osobné údaje. Vaše súkromie a bezpečnosť údajov sú zaistené.",
    faq_question_3: "Prečo Gbox žiada o toľko povelení?",
    faq_answer_3: "GBox žiada o potrebné povolenia, aby sa zabezpečilo správne fungovanie aplikácií. Napríklad GBox žiada o povolenia upozornení, aby  používatelia mohli obdržať upozornenie na prijatú správu.",
    support_links_title: 'Za više <strong> pitanja o projzvodima</strong>',
    service_title: 'Servisni centar',
    service_action: 'Pronađite servisni centar',
    help_center_title: 'Pronađi više',
    help_center_action: 'Više informacija na uređajima',
    contact_title: 'Kontakt',
    contact_action: 'Kontakt centar',
  },
  sl: {
    about_button: 'O aplikacijah na HMS',
    about_text: 'AppsOnHMS je učni portal o razpoložljivosti aplikacij v različnih trgovinah in o tem, kako namestiti želeno aplikacijo v napravo Huawei. Prikazani rezultati so ustvarjeni iz javno dostopnih vsebin in se lahko občasno spremenijo',
    tagline: 'Vaša <strong>online knjižnica</strong> za aplikacije na <strong>napravah HUAWEI</strong>',
    search_placeholder: 'Poiščite aplikacijo...',
    description_appgallery: 'Huawei AppGallery je uradna trgovina z aplikacijami Huawei, s katero lahko prenesete na stotine aplikacij za Android. Aplikacija odlično deluje s katerim koli pametnim telefonom z operacijskim sistemom Android, vendar morate v pametnem telefonu imeti nameščeno aplikacijo za mobilne storitve Huawei, da bo pravilno delovala pri drugih blagovnih znamkah.',
    description_petalsearch: 'Petal Search je Huawei aplikacija za iskanje, ki uporabnikom omogoča preprosto iskanje in iskanje stvari, ki jih potrebujejo - vključno z aplikacijami, novicami, videoposnetki in drugimi vsebinami.',
    description_gspace: 'Nov način 2021 za uporabo vročih aplikacij, ki deluje z vsemi aplikacijami v telefonu. Gspace ponuja funkcijo, da se lahko z enim telefonom enostavno prijavite v več računov in hkrati ohranite vse na spletu! Podatki iz različnih računov ne bodo posegali drug v drugega.',
    description_apkpure: 'Aplikacija APKPure (APK Downloader) je uradno izdana! Z APKPure lahko takoj prenesete in posodobite zaklenjeno regijo (ni na voljo v vaši državi), vnaprej registrirane igre in številne druge aplikacije v sistemu Android.',
    featured_title: 'Predstavljene <strong> aplikacije &amp; Igre </strong>',
    sources_title: 'Na voljo na',
    curated_guides_title: 'Vodniki',
    youtube_guides_title: 'Rezultati iz YouTuba',
    youtube_search_title: 'Poiščite več',
    youtube_search_button: 'Več vodnikov poiščite v YouTubu',
    no_results_title: 'Nismo našli ujemanja za:',
    no_results_text: 'Poskusite z drugim iskanjem ali si oglejte spodnje video predloge, kako namestiti aplikacije',
    stores_title: 'Različne metode za <strong>prenos</strong>, <strong>nameščanje</strong> in <strong>rabo aplikacij</strong>',
    store_info_button: 'Preberite več',
    store_visit_button: 'Pojdite na',
    app_details_button: 'Več',

    faq_title: 'Pogosta vprašanja',
    faq_question_1: "Kaj je Gbox?",
    faq_answer_1: "GBox je aplikacija, ki zagotavlja različne funkcije, vključno z zaščito zasebnosti in možnostjo zagona več kopij aplikacije. Aplikacije, ki temeljijo na GMS, lahko prek GBoxa uporabljate tudi v napravah, ki ne podpirajo GMS.  Z enkratno namestitvijo lahko uživate v funkcijah aplikacije GBox na katerem koli telefonu s sistemom Android.",
    faq_question_2: "Težave z varnostjo in zaščito Gbox-a",
    faq_answer_2: "GBox nikoli ne bo zbiral nobenih osebnih podatkov. Vaša zasebnost in varnost podatkov sta zagotovljeni.",
    faq_question_3: "Zakaj GBox zaprosi za toliko dovoljenj?",
    faq_answer_3: "GBox zaprosi za potrebna dovoljenja, da zagotovi pravilno delovanje aplikacij. GBox na primer zaprosi za dovoljenja za obvestila, da lahko uporabniki pravočasno prejmejo obvestila o sporočilih.",
    support_links_title: 'Za več <strong>vprašanje glede izdelkov</strong>',
    service_title: 'Servisni center',
    service_action: 'Poiščite servisni center',
    help_center_title: 'Poiščite več',
    help_center_action: 'Več informacij o napravah',
    contact_title: 'Kontakt',
    contact_action: 'Klicni center',
  },
  uk: {
    about_button: 'Про AppsOnHMS',
    about_text: 'AppsOnHMS - це навчальний портал про доступність додатків в різних магазинах  і про те, як встановлювати потрібні застосунки на ваш пристрій Huawei. Показані результати отримані з загальнодоступних джерел і можуть час від часу змінюватися',
    tagline: 'Ваша <strong>онлайн бібліотека</strong> додатків на <strong>пристроях HUAWEI</strong>',
    search_placeholder: 'Знайдіть додаток...',
    description_appgallery: 'Huawei AppGallery -це офіційний магазин додатків Huawei, який ви можете використовувати для завантаження Android-додатків. Він відмінно працює з будь-яким смартфоном з операційною системою Android, але вам необхідно встановити додаток Huawei mobile services на свій смартфон для коректної роботи з іншими брендами.',
    description_petalsearch: 'Petal Search - це пошуковий додаток, розроблений Huawei, який дозволяє користувачам легко шукати і знаходити те, що їм потрібно, включаючи додатки, новини, відео та багато іншого.',
    description_gspace: 'Новий метод 2021 року для використання гарячих додатків, що працює з усіма додатками у вашому телефоні. GSpace надає функцію, за допомогою якої ви можете легко використовувати один телефон для входу в кілька облікових записів та підтримувати їх в мережі одночасно! Дані з різних облікових записів не заважатимуть один одному.',
    description_apkpure: 'Додаток APKPure (APK Downloader) офіційно випущено! За допомогою APKPure ви можете миттєво завантажувати та оновлювати недоступні у вашій країні, попередньо зареєстровані ігри та багато інших Android-додатків.',
    featured_title: 'Рекомендовані <strong>Програми та Ігри</strong>',
    sources_title: 'Доступно на',
    curated_guides_title: 'Посібник',
    youtube_guides_title: 'Результати з YouTube',
    youtube_search_title: 'Знайдіть більше',
    youtube_search_button: 'Знайдіть більше посібників на YouTube',
    no_results_title: 'Не вдалося знайти відповідність для:',
    no_results_text: 'Будь ласка, спробуйте інший пошук або перегляньте відео пропозиції щодо встановлення програм нижче',
    stores_title: 'Різноманіття методів <strong>завантаження</strong>, <strong>встановлення</strong> та <strong>використання додатків</strong>',
    store_info_button: 'Читати більше ',
    store_visit_button: 'Перейти',
    app_details_button: 'Більше',
    faq_title: 'Популярні запитання (FAQ)',
    faq_question_1: "Що таке Gspace і як він працює? ",
    faq_answer_1: "Gspace це сторонній магазин додатків для сервісів і додатків Google. Він простий у використанні і включає в себе Google Map, Uber, Youtube і мільйони інших додатків, які вам можуть знадобитися.",
    faq_question_2: "Проблема безпеки особистих даних",
    faq_answer_2: "Особисті дані користувача не будуть збиратися або використовуватися додатком Gpace. Облікові записи перевіряються з власного сервера додатка.",
    faq_question_3: "Чи будуть додатки з Gspace мати повну функціональність?",
    faq_answer_3: "Майже всі додатки можуть використовуватися і завантажуватися з повною функціональністю. У Gspace є спеціальна команда для поліпшення користувацького досвіду. При виникненні будь-яких проблем користувачі можуть в будь-який час залишити відгук через панель навігації Gspace.",
    support_links_title: 'Отримати іншу інформацію <strong>пов\'язану з продуктами</strong>',
    service_title: 'Сервісний центр',
    service_action: 'Знайти сервісний центр',
    help_center_title: 'Знайти більше',
    help_center_action: 'Більше інформації про пристрої',
    contact_title: 'Контакти',
    contact_action: 'Служба підтримки',
  },
  // xx: {
  //   about_button: '',
  //   about_text: '',
  //   tagline: '',
  //   search_placeholder: '',
  //   description_appgallery: '',
  //   description_petalsearch: '',
  //   description_gspace: '',
  //   description_apkpure: '',
  //   featured_title: '',
  //   sources_title: '',
  //   curated_guides_title: '',
  //   youtube_guides_title: '',
  //   youtube_search_title: '',
  //   youtube_search_button: '',
  //   no_results_title: '',
  //   no_results_text: '',
  //   stores_title: '',
  //   store_info_button: '',
  //   store_visit_button: '',
  // }
}

import Axios from 'axios'
import { ElMessageBox } from "element-plus";
import config from "@/config";

export class ApiError extends Error {
  constructor(
    public code: number,
    public message: string,
    public data: any | null
  ) {
    super(message);
  }
}

const apiClient = Axios.create({
  baseURL: config.apiBaseUrl
})

apiClient.interceptors.response.use((response) => {
  if (response.status === 200) {
    const body = response.data
    if (typeof body === 'object' && body.error) {
      ElMessageBox.alert(body.error.message, "Error");
      throw new ApiError(body.error.code, body.error.message, body.data)
    }
  } else {
    ElMessageBox.alert('API Server Error')
    console.error('API Server Error')
    throw new ApiError(response.status, response.statusText, response.data)
  }

  return response.data
}, (error) => {
  return Promise.reject(error)
})

export default apiClient
